import process from 'process';

type PaymentLinkParams = {
	locationId: string | undefined,
	amount?: number,
	customAmounts?: number[],
	companyId?: string,
	clientId?: string,
	invoiceId?: string,
	invoiceNumber?: string
};

export default function generatePaymentLink( {
	locationId,
	amount,
	customAmounts,
	companyId,
	clientId,
	invoiceId,
	invoiceNumber,
}: PaymentLinkParams ) {
	if ( !locationId ) {
		return null;
	}
	
	let link = `${process.env.NEXT_PUBLIC_SITE_URL}/p/${locationId}/payment`;
	let encodedQueryObject: { [ key: string ]: any } = {};
	
	if ( customAmounts?.length || amount || invoiceNumber || invoiceId || clientId ) {
		encodedQueryObject = {
			...amount && { amount },
			...customAmounts?.length && {
				customAmounts,
				companyId,
			},
			...invoiceNumber && { invoiceNumber },
			...invoiceId && { invoiceId },
			...clientId && { client: clientId },
		};
	}
	
	if ( Object.keys( encodedQueryObject ).length > 0 ) {
		const filters = encodeURIComponent(
			Buffer.from( JSON.stringify( encodedQueryObject ) ).toString( 'base64' ),
		);
		link += `?a=${filters}`;
	}
	
	return link;
};
